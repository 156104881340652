import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router} from "@angular/router";
import {environment} from "../environments/environment";
import {DialogHelperService} from "../shared/services/dialog-helper.service";
import {LoginHelperService} from "../shared/services/login-helper.service";
import {OrganizationStateService} from "../shared/services/organization-state.service";
import {WorkspacePermissionService} from "../shared/services/workspace-permissions.service";
import {PlanHelperService} from "../shared/services/plan-helper.service";
import {UserHelperService} from "../shared/services/user-helper.service";
import {isPlatformServer} from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class OrganizationPlanGuardService {

    constructor(
        private planHelperService: PlanHelperService,
        private userHelperService: UserHelperService,
        private router: Router,
        private dialogHelperService: DialogHelperService,
        private organizationStateService: OrganizationStateService,
        private workspacePermissionService: WorkspacePermissionService,
        private loginHelperService: LoginHelperService,
        @Inject(PLATFORM_ID) private platformId: object
    ) {

    }

    async canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {

        if (isPlatformServer(this.platformId)) {
            return true;
        }

        const {orphanedAccountUrl} = environment.globals;

        try {
            if (!this.loginHelperService.isUserLoggedIn()) {
                this.router.navigate([environment.globals.welcomeAppPage]);
                return false;
            }
            if (await this.planHelperService.isOrphanedAccount() && _state.url !== orphanedAccountUrl) {
                this.router.navigate([orphanedAccountUrl]);
                this._openConfirmationDialog();
                return false;
            }

            return true;

        } catch (error) {
            console.error(error);
            return false;
        }
    }

    private _openConfirmationDialog = () => {
        const msg = `
            This organization was cancelled by its owner. Please contact the owner for details,
            or create a new individual account with a new email.
        `;

        this.dialogHelperService.showConfirmationDialog(
            msg,
            "Register with new email",
            null,
            null,
            this._redirectToRegister,
            null,
            null,
            null,
            "orphans-confirmation-dialog-content",
            "Your Organization was Cancelled",
        );
    }

    private _redirectToRegister = () => {
        this.userHelperService.clearUser();
        this.planHelperService.clear();
        this.organizationStateService.clear();
        this.workspacePermissionService.clear();
        this.router.navigate(['register']);
        this.dialogHelperService.close();
    }

}
